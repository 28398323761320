.indicator_card{

    &-body {
        border: 1px solid #131421;
        border-radius: 10px;
        background-color: #FFFFFF;
        margin: 15px;
        padding: 30px;
        height: 475px;
        max-width: 540px;
        transition: all 0.4s ease-in-out;
        &:hover{
            box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
            cursor: pointer;
        }
        
    }

    .infrastructure{
        background-color: #2E70AC;
    }
    .investment{
        background-color: #FBB900;
    }
    .people{
        background-color: #F73E55;
    }
    .ecosystem {
        background-color:#38B99F;
    }
    .impact {
        background-color:#BF77FF;
    }
    .filtered{
        height: 50px;
        width: 50px;
        color: #FFFFFF;
        border-radius: 50%;
        font-family: Catamaran;
        font-size: 22px;
        font-weight: bold;
        text-align: center;

    }
    h4{
        font-family: Catamaran;
        font-size: 22px;
        font-weight: 500;
        color: #2D3036;
        text-transform: unset;
        line-height: 24px;
    }
    .col-12.mt-3.mb-3{
        height: 50px;
        overflow: hidden;
    }
    p{
        font-size: 18px;
        font-family: Catamaran;
        color: #4A4A4A;
    }
    svg{
        width: 100%;
        height: 100%;
    }
    // Mobile specific styling
  @media (max-width: $tablet) {
    margin: 30px 0px;
    .filtered{
        height: 46px;
        width: 46px;
        }
    }
}