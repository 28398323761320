.navigation {
//   General styling
position: relative;
.nav-tool:hover > #nav-tool{
  display: block !important;
}
.nav-about:hover > #nav-about{
  display: block !important;
}

.navbar{
  &-nav{
    .nav-item {
      &.active, &:hover{
        .nav-link{
          color: #EC9744;
        }
      }
      .nav-link{
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        margin-right: 20px;
        text-transform: uppercase;
      }
    }
  }
  &-brand{
    img{
      width: 113px;
      height: 40px;
    }
  }
}
// Desktop specific styling
@media (min-width: $tablet) {} 

// Mobile specific styling
  @media (max-width: $tablet) {
    .fa-chevron-down{
      float: right;
    }
    a.test{
      color: #FFFFFF;
      font-size: 12px;
      text-decoration: none;
    }
    .navbar{
      &-nav{
        .nav-item {
          &:last-of-type{
            border-bottom: none;
          }
          border-bottom: 1px solid #fff;
          .dropdown-item{
            color: #FFFFFF;
            font-size: 12px;
          }
          .dropdown-menu{
            padding-left: 30px;
            border-top: 1px solid #fff;
            border-radius: 0;
          }
          li.dropdown-submenu{
            border-bottom: 1px solid #fff;
            &:last-of-type{
              border-bottom: none;
            }
            .dropdown-menu{
              padding-left: 0px;
            }
          }
          .nav-link{
            text-align: left;
            color: #FFFFFF;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}