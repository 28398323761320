// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

h1,h2,h3,h4,h5,h6, p, span, label, input, ul {
  @include no-margin-padding;
}
// Force the mobile max-width
.container {
  //max-width: $content-grid;
}

body {
  padding-bottom: 0 !important; // Overrides sharethis inline styles
  > .content {
    padding-top: calc(#{$navigation-height-desktop} - 2px);
    background: white;
  }
}

.starter-background {
  background-color: #35334B !important;

}
.grid-image {
  content: url('/static/src/svg/grid.svg');
      position: fixed;
    right: 0;
    bottom: 0;
}
mark {
  padding: 3px 0 !important;
  background-color: #fcecdd !important;

}
/**
* Make all elements from the DOM inherit from the parent box-sizing
* Since `*` has a specificity of 0, it does not override the `html` value
* making all elements inheriting from the root box-sizing value
* See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*/
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: $background-colour;
  // background: #2d3036;
//   height: 100vh;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-primary;
  text-decoration: none;

  @include on-event {
    color: $font-general;
    text-decoration: underline;
  }
}

input {
  width: 100%;
  outline: none;
  // Hide the default date picker arrow
  &::-webkit-calendar-picker-indicator, 
  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    display: none;
  }
  // Hide the default date picker prompt
  &[type="date"]::-webkit-input-placeholder{ 
    visibility: hidden !important;
  }
}

hr {
  border-top: 2px solid;
  opacity: .5;
  &.dashed {
    background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 11px 2px;
    background-repeat: repeat-x;
    border-top: unset !important;
    border: unset;
    height: 2px;
    opacity: .3 !important;
    margin-bottom: 10px;
  }
  &.light {
    border-color: #B2BEC3 !important;
  }
  &.requests {
    margin-bottom: 20px;
    margin-top: 20px !important;
  }
}

a {
  cursor: pointer;
}

.module {
  @include module;
  > .container {
    padding: 0;
  }
}

.component {
  margin: $s-s 0;
}

label {
//   font-size: 11px;
//    text-transform: uppercase;
//   font-weight: 300;
//   margin-bottom: 0;
//   margin-right: 5px;
//   color: #636E72;
}

.show {
  display: block !important;
}

.highlight {
  color: $brand-primary !important;
  font-weight: 500 !important;
}

.anchor {
  top: -$navigation-height-desktop - $s-t;
  position: relative;
  visibility: hidden;
  display: block;
}
.activeClass {
  text-decoration: underline;
  letter-spacing: 0px;
  color: #EE7E18 !important;
}
.Pulsar-module_container__iLGP9 { 
    position: absolute !important;
    left: 0px;
    right: 0;
    margin: auto;
    top: 160px;
    bottom: 0;
}
[data-title] {
  font-size: 30px; /*optional styling*/
  position: relative;
}

[data-title]:hover::before {
  content: attr(data-title);
  position: absolute;
  display: inline-block;
  padding: 3px 6px;
  font-size: 12px;
  font-family: sans-serif;
  white-space: nowrap;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #E3E3E3;
}

  // Desktop specific styling
  @media (min-width: $tablet) {
  }
  
  // Mobile specific styling
  @media (max-width: $tablet) {
    .daterangepicker {
      display: none !important;
    }
  }
  