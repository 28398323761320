.login {
    &--container {
        width: 620px;
        height: 309px;
        /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 16px #00000029;
        padding: 33px 24px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: 16px;
        z-index: 1;
        h1 {
            font: bold 20px/23px Arial;
            letter-spacing: 0px;
            color: #4C4D5D;
        }
        a {
            text-decoration: underline;
            font: normal 14px/16px Arial;
            letter-spacing: 0px;
            color: #BEBFC7;
        }
        .submit-container{
            justify-content: end;
            display: flex;
        button {
            width: 98px;
            height: 38px;
            /* UI Properties */
            background: #EE7E18 0% 0% no-repeat padding-box;
            border-radius: 12px;
            font: normal 16px/18px Arial;
            letter-spacing: 0px;
            color: #FFFFFF;
            margin-top: 4px;
            border: none;
        }
    }
    }
}