.filter{
    p{
        color: #2D3036;
        font-family: Catamaran;
        font-size: 18px;
        font-weight: 500;
    }
    .btn{
        color: #FFFFFF !important;
        font-family: Catamaran;
        font-size: 16px;
        font-weight: bold;
        width: 160px;
    }
    .btn-people{
        background-color: #F73E55;
        border: 1px solid #F73E55;
        &.selected{
            background-color: #FFFFFF;
            color: #F73E55 !important;
        }

    }
    .btn-investment{
        background-color: #FBB900;
        border: 1px solid #FBB900;
        &.selected{
            background-color: #FFFFFF;
            color: #FBB900 !important;
        }
    }
    .btn-infrastructure{
        background-color: #2E70AC;
        border: 1px solid #2E70AC;
        &.selected{
            background-color: #FFFFFF;
            color: #2E70AC !important;
        }
    }
    .btn-ecosystem{
        background-color: #38B99F;
        border: 1px solid #38B99F;
        &.selected{
            background-color: #FFFFFF;
            color: #38B99F !important;
        }
    }   
     .btn-impact{
        background-color: #BF77FF;
        border: 1px solid #BF77FF;
        &.selected{
            background-color: #FFFFFF;
            color: #BF77FF !important;
        }
    }
      // Mobile specific styling
  @media (max-width: $tablet) {
      .btn{
        margin-top: 10px;
        margin-left: 90px;
      }
  }
}