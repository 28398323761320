// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
    font-family: 'din_condensedbold';
    src: url('../../fonts/dincondensed-bold-webfont.woff2') format('woff2'),
         url('../../fonts/dincondensed-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'bebas_neueregular';
    src: url('/static/src/fonts/bebasneue-regular-webfont.woff2') format('woff2'),
         url('/static/src/fonts/bebasneue-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}