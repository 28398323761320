.innovate_card{
    font-family: Catamaran;
    border: 1px solid #131421;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: $box-shadow;
    max-width: 540px;
    margin: 15px 0px;
    padding: 25px;
    &--listings{
        padding-top: 25px;
        padding-bottom: 25px;
    }
    &--header{
        color: #2D3036;
        .preview-image{
            height: 128px;
            width: 128px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
        h4{
            font-size: 30px;
            text-transform: uppercase;
            font-weight: bold;
            line-height: 32px;
        }
        p{
            font-size: 22px;
            font-weight: 500;
            line-height: 26px;
        }
    }
    &--content{
        margin-top: 25px;
        .video{
            height: 186px;
            img{
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 100%;

            }
        }
        p{
            font-size: 18px;
            font-weight: 200;
            max-height: 189px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    &:hover{
        box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
        cursor: pointer;
    }
// Mobile specific styling
  @media (max-width: $tablet) {
    margin: 30px 3px;
    &--header{
        .preview-image{
            width: 100%;
        }
    }
    &--content{
        .video{
            margin: 30px;
        }
    }
  }
}