.password-container{
    position: relative;
    input[type="password"],input[type="text"] {
        // General styling
        position: relative;
        font: normal 14px/16px Arial;
        color: #35344B;
        transition: 0.2s all ease-in-out;
        background: #FFFFFF;
        height: 54px;
        width: 100%;
        border: 1px solid #F5F5F7;
        border-radius: 7px;
        padding-left: 11px;
        box-sizing: border-box;
        letter-spacing: 0px;
        // Placeholder
        &::placeholder {
            font: normal 14px/16px Arial;
            letter-spacing: 0px;
            color: #35344B;
            opacity: 0.7;
        }
    
    //     &:focus {
    //         border: $border-focused;
    //     }
    
    //     &.error {
    //         border-color: $error;
    //     }
    
    //     // State classes
    //     &.error {
    //         border-color: $error;
    //     }
    
    //     &:disabled,.disabled {
    //         background: $grey_white_tint;
    //         border: $border-disabled;
    //         box-sizing: border-box;
    //         color: $grey;
    //     &::placeholder {
    //         color: $grey;
    //         border-color: $border-disabled;
    //         }
    // }
    }
    .dynamic {
        border: 1px solid #F5F5F7;
        max-height: 56px;
        min-height: 56px;
        border-radius: 7px;
        box-sizing: border-box;
        input[type="text"],input[type="password"] {
            background: transparent;
            width: 99%;
            border: 0;
            border-radius: 7px;
            padding-left: 14px;
            height: unset;
            margin: 0;
            float: unset;
            &:focus,&:focus-visible,&:focus-within,&:active {
                border: 0 !important;
                outline: none !important;
            }
        }
        label {
            margin: 6px 14px;
        }
        .password-container-toggle {
            top: 20px;
        }
        }
        // .dynamic.error {
        //     border:1px solid $error;
        // }
        // .dynamic.disabled {
        //     border: $border-disabled;
        // }
    &-toggle {
        font: normal 14px/16px Arial;
        font-size: 13px !important;
        text-decoration-line: underline;
        color: #3F4142;
        letter-spacing: 0.5px;

        text-align: center;
        color: #7d7d7d;
        position: absolute;
        cursor: pointer;
        right: 18px;
        top: 27px;
        }
        &-toggle.disabled {
            color: #EAECEC;
            cursor: not-allowed !important;
        }
    // Desktop specific styling
    @media (min-width: $tablet) {
    }
  
    // Mobile specific styling
    @media (max-width: $tablet) {
    }
}
  

  
  
  
  