
.sidebar_right {

    // Local Variables
    $totalWidth: 332px;
    $panelWidth: 332px;
    $tabHeight: 50px; 
    $vertical: 82px;
    
    // General styling
    display: inline-block;
    position: fixed;
    top: $vertical;
    height: calc(100vh - #{$vertical}); // minus top height
    overflow: hidden;
    right: -$totalWidth;
    width: $totalWidth;
    transition: .2s all ease-in-out;
    z-index: 1000;
    &--tab {
      position: relative;
      padding: 3px 20px;
      width: 100%;
      text-align: center;
      padding: 15px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
      background: white;
      border-radius: 0;
      cursor: pointer;
      span {
        z-index: 1;
        position: relative;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        line-height: 24px;
        font-size: 24px;
        color: white;
        font-weight: 600;
        top: 3px;
      }
      i {
        z-index: 1;
      }
      .circle {
        position: absolute;
        left: 15px;
        top: 12px;;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-color: #B2BEC3;
        background-color: #EE7E18;
        border-radius: 50%;
        .fas, svg {
          color: white;
        }
      }
      &.short {
        background: #3388ff;
      }
    }
    &--panel {
      margin: 0;
      width: $totalWidth;
      position: relative;
      right: 0;
      background-color: white;
      padding: 20px 31px !important;
      box-shadow: 0px 9px 9px 0px rgba(0,0,0,0.1);
      border-radius: 0 0 0 5px;
      height: 100%;
      hr {
        color: #00000030;
        height: 1px;
        margin-left: -12px;
    margin-right: -12px;
      }
      h4 {
        font: normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #707184;
        opacity: 1;
        text-transform: unset;
      }
      p {
        margin-bottom: 22px;
      }
      .description {
        font: bold 14px/17px Arial;
        letter-spacing: 0px;
        color: #4C4D5D;
      }
      h1 {
        font: bold 20px/23px Arial;
        letter-spacing: 0px;
        color: #35344B;
      }
      .count {
        h6 {
            font: bold 18px/24px Arial;
            letter-spacing: 0px;
            color: #EE7E18;
            min-width: 30px;
            height: 30px;
            text-align: center;
            padding: 3px 5.5px;
            /* UI Properties */
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #E3E3E3;
            border-radius: 7px;
            img {
              position: relative;
              bottom: 2.5px;
            }
        }
        p {
            font: italic 14px/24px Arial;
            letter-spacing: 0px;
            color: #35344B;
            margin: 0 0 0 13px;
            padding-top: 3px;
        }
      }
      .list-container{
         height: calc(100vh - 296px);
         overflow: hidden;
         overflow-y: scroll;
         /* width */
          &::-webkit-scrollbar {
            width: 7px;
          }
                  /* Track */
          &::-webkit-scrollbar-track {
            background: #FFFFFF; 
          }
          
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #F4F4F4 ; 
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #F4F4F4; 
          }
        .list {
          margin-bottom: 15px;
          &:hover {
            text-decoration: underline;
          }
          a {
              &:first-child {
                  font:  bold 14px/20px Arial;
                  letter-spacing: 0px;
                  color: #35344B;
              }
              &:last-child {
                  font:  normal 14px/20px Arial;
                  letter-spacing: 0px;
                  color: #35344B;
              }
          }
        }
      }
      .website{
        font: normal 14px/17px Arial;
        width: 98px;
        height: 33px;
        /* UI Properties */
        border: 1px solid #DFE0E5;
        border-radius: 7px;
        padding: 7px 10px;
        color: #4C4D5D;
        text-decoration: none;
        img {
            margin-right: 12px;
            position: relative;
            bottom: 2px;
        }
      }
      .profile-listing {
        width: 135px;
        height: 36px;
        /* UI Properties */
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 12px #00000029;
        border: 1px solid #35334B;
        text-align: center;
        font: normal 16px/26px Arial;
        letter-spacing: 0px;
        color: #35344B;
        padding: 7px 14px;
        border-radius: 16px;
      }
    }
    &.open {
      right: 0;
      i.circle {
        transform: rotate(180deg);
      }
    }
    // Desktop specific styling
    @media (min-width: $tablet) {
    }
    
      // Mobile specific styling
      @media (max-width: $tablet) {
        display: none;
      }
    }
    
    