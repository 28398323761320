.css {
    &-1xh8qep-loadingIndicator {
        display: none !important;
    }
    &-yk16xz-control {
        min-height: 31px !important;
        height: 31px !important;
        background-color: transparent !important;
        border: none !important;
        &:hover,&:active,&:focus,&:focus-visible {border: none !important;box-shadow: none !important;outline: none !important;}
    }
    &-1pahdxg-control {
        background-color: transparent !important;
        min-height: 31px !important;
        height: 31px !important;
        outline: none !important;
        border: none !important;
        &:hover,&:active,&:focus,&:focus-visible {border: none !important;box-shadow: none !important;outline: none !important;}
    }
    &-1hwfws3 {
        position: relative !important;
        bottom: 2px !important;
    }
    &-tj5bde-Svg {
        display: none !important;
    }
    &-1wa3eu0-placeholder {
        font: normal 14px/16px Arial !important;
        letter-spacing: 0px !important;
        color: #35344B !important;
        margin-left: 6px !important;
        opacity: 0.7;
    }
    &-1okebmr-indicatorSeparator {
        display: none !important;
    }
}