.funder-alert {
    width: 359px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 25px #00000029;
    border-radius: 7px;
    padding: 26px 59px;
    position: fixed;
    top: 115px;
    right: 31px;
    a {
        position: absolute;
        right: -39px;
        top: -14px;

    }
    .alert-header {
        font: bold 16px/18px Arial;
        letter-spacing: 0px;
        color: #35344B;
        &.success::before {
            content: "\f00c";
            background-color: #34AA53 ;
            @include alert-message-icon;

        }
        &.failed::before  {
            content: "\f12a";
            background-color: #D93025  ;
            @include alert-message-icon;
        }
    }
    .alert-body {
        font: normal normal normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #707184;
        margin-top: 5px;
    }
}