.alert{
    background-color: #6EB7CB;
    color: #FFFFFF;
    position: absolute;
    right: 0;
    max-width: 340px;
    width: 90%;
    z-index: 3;
    top: 150px;
    margin: auto;
    opacity: 0.95;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
    margin-bottom: 15px;

    .col-sm-8{
      width: 67%;
    }
    .col-sm-4{
      width: 33%;
    }

    .close{
      padding: 0px 15px !important;
      span{
        font-size: 45px !important;
      }
      &:hover {
        color: white;
      }
    }
    .successful{
      margin-top: 15px;
      .fas{
        color: #B8E986;
        font-size: 60px;
      }
    }
    .unsuccessful{
      margin-top: 15px;
      .fas{
        color: #F73E55;
        font-size: 60px;
      }
    }
    .warning{
      margin-top: 15px;
      .fas{
        color: #F2C56D;
        font-size: 60px;
      }
    }
    .declined{
      color:#EF3E55;
    }
    .accepted{
      color: #B3D194;
    }
    &-invited{
      color: #F2C56D;
    }
    // Desktop specific styling
    @media (min-width: $tablet) {
    
    }
        
    // Mobile specific styling
    @media (max-width: $tablet) {
    }
  }