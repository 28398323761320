.innovate-navbar {
    height: 82px;
    background: #333448;
    width: 100%;
    z-index: 100;
    position: sticky;
    top: 0;
    padding: 17px 23px;
    .logo {
        margin-right: 52px;
        border:none;
        margin-left:0;
    img {
        height: 48px;
    }
    }
    .sign-in {
        margin-top: 5px;
    }
    a {
        border: 1px solid #FFFFFF;
        border-radius: 16px;
        text-align: center;
        font: normal 16px/26px Arial;
        letter-spacing: 0px;
        color: #FFFFFF !important;
        padding: 4px;
        width: 98px;
        height: 36px;
        margin-left: 16px;
        text-decoration: none !important;
        
    }
}