.label {
    // General styling
    white-space: nowrap;
    text-align: left;
    font: normal  12px/14px Arial;
    letter-spacing: 0px;
    color: #BEBFC7;
    margin: 8px 0 8px;
    display: block;
    text-transform: unset;
    &.icon-left {
      margin-left: 38px !important;
    }
    // Error state
    // &.error {
    //   color: $error;
    // }    
    // &.disabled {
    //   border-color: $border-disabled;
    //   color: $grey;
    // }

  
    // &.disabled {
    //   border-color: $disabled;
    //   color: $disabled;
    // }
  
    // Desktop specific styling
    @media (min-width: $tablet) {}
  
    // Mobile specific styling
    @media (max-width: $tablet) {}
  }