.footer {
// General styling
    height: 278px !important;
    background-color: #272730;
    padding: 30px;
    font-family: Catamaran;
    a{
      color: #FFFFFF;
      display: block;
      text-decoration: none;
      &:hover{
        color: #EC9744;
      }
    }
  &-title{
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: #FFFFFF;
  }
  .get_in_touch, .quick_links{
    font-size: 12px;
    font-weight: 500;
    a{
      padding: 15px 0px;
    }
  }
  .get_in_touch{
    a{
      padding: 5px 0px;
    }
  }
  .bottom-border{
    border-bottom: 1px solid #979797;
  }
  .social_media{

    a{
      display: inline-block;
      text-align: center;
      margin-right: 39px;
      .fa{
        font-size: 30px;
      }
    }
  }
  &--sub {
    height: 67px;
    padding: 15px 0px;
    background-color: #1E1E26;
    p{
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 500;
    }
  }
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    height: unset !important;
    .quick_links, .social_media{
      margin-top: 60px;
    }


  }
}