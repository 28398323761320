.hero {
// General styling
transition: .2s all ease-in-out;
background: url("/static/dist/img/image_hero-banner_hands.jpg") center / cover;
height: 300px;
  h1 {
    font-size: 60px;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    font-family: Catamaran;
  }

// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    h1{
      font-size: 40px;
    }

}
}