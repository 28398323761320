
.text-field {
    width: 100%;
    position: relative;
    &.right {
        margin-right: 5px;
      }
    &.left {
          margin-left: 5px;
      }
        .input-container {
            position: relative;
        }

    input[type="text"],input[type="number"] {
        background: #FFFFFF;
        height: 54px;
        width: 100%;
        border: 1px solid #F5F5F7;
        border-radius: 7px;
        padding-left: 11px;
        box-sizing: border-box;
        margin: 0;
        float: unset;

        font: normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #35344B;

        &::placeholder {
            font: normal 14px/16px Arial;
            letter-spacing: 0px;
            color: #35344B;
            opacity: 0.7;
        }
        // &:active,
        // :focus {
        //    border: $border-focused !important;
        // }

        // &:disabled,.disabled {
        //         background: $grey_white_tint;
        //         border: $border-disabled;
        //         box-sizing: border-box;
        //         color: $grey;
        //     &::placeholder {
        //         color: $grey;
        //         border-color: $border-disabled;
        //         color:$grey;
        //         }
        // }&.error {
        //     border-color: $error;
        // }

    }
    .dynamic {
        border: 1px solid #F5F5F7;
        max-height: 56px;
        min-height: 56px;
        border-radius: 7px;
        box-sizing: border-box;

        input[type="text"],input[type="number"] {
            background: transparent;
            width: 99%;
            border: 0;
            border-radius: 7px;
            padding-left: 12px;
            height: unset;
            margin: 0;
            float: unset;
            &:focus,&:focus-visible,&:focus-within,&:active {
                border: none !important;
                outline: none !important;
            }
        }
        label {
            margin: 10px 14px 0 14px;
        }
    }
    // .error {
    //     border-color: $error;
    // }
    // .disabled {
    //     background: $grey_white_tint;
    //     box-sizing: border-box;
    //     color: $grey;
    // }
    //  .dynamic.disabled {
    //     border: $border-disabled;
    // }
}
.text-field.dynamic {
     position: relative;
     margin-bottom: 10px;

    .fa-search ,.fa-map-marker-alt{
            top: calc(100% - 58px);
        }
}