.modal {
    &-button {
        border: 1px solid #333348 !important;
        border-radius: 16px !important;
        background-color: #FFFFFF !important;
        height: 36px;
        width: 127px;
        font: normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #333448 !important;
    }
    &-dialog {
        margin: 0 !important;
        right: 20px;
        position: absolute !important;
        top: 156px;
        font: normal normal normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #333448 !important;
        width: 390px !important;
        padding: 26px !important;
    }
    &-header {
        border: none !important;
        padding-bottom: 0 !important;
        padding: 26px 26px 0 26px !important;
        .close {
            position: relative !important;
            top: -23px !important;
        }
    }
    &-footer {
        border: none !important;
        justify-content: space-between !important;
        padding: 0 26px 26px 26px !important;
    
        button {
            width: 131px !important;
            height: 36px !important;
            border-radius: 16px;
            text-align: center;
            font: normal normal normal 14px/16px Arial;
            letter-spacing: 0px;
            &:first-child {
                border: 1px solid #5C5A77;
                color: #5C5B77;
                background-color: white;
            }
            &:last-child {
                background: #EE7E18 0% 0% no-repeat padding-box;
                border: 1px solid #EE7E18;
                color: #FFFFFF;
            }
        }
    }
    &-title {
        font: bold 16px/18px Arial;
        letter-spacing: 0px;
        color: #35344B;
        border: none !important;
        text-transform: unset !important;
    }
    &-body {
        font: normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #707184;
        border: none !important;
        padding: 6px 26px 14px 26px !important;
    }
    &-content {
        border: none !important;
        box-shadow: 0px 2px 25px #00000029;
        border-radius: 7px;
    }
}