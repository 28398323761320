@-webkit-keyframes fade {
    0% {opacity: 0.2} 
    50% {opacity: 1}
    100% {opacity:0.2}
    }
    
    @-moz-keyframes fade{
        0% {opacity: 0.2} 
      50% {opacity: 1}
      100% {opacity:0}  
    }
    
    @keyframes fade {
      0% {opacity: 0.2} 
      50% {opacity: 1}
      100%{opacity: 0.2}
        }
    @-o-keyframes fade {
    0% {opacity: 0.2} 
      50% {opacity: 1}
      100%{opacity: 0.2}
    
    }
.overview {
    &--funders_dashboard_home {
        &-header {
            text-align: center;
            font: 500 72px/86px "bebas_neueregular";
            letter-spacing: 0px;
            color: #EE7E18;
            text-transform: uppercase;
            opacity: 1;
            margin: 107px 17px 41px 0;
        }
        &-container {
            display: grid;
            grid-template-columns: auto auto auto auto;
            padding: 0 47px;
        }
        &-items {
            width: 222px;
            height: 168px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #171B31;
            border-radius: 12px;
            text-align: center;
            margin-bottom: 30px;
            padding: 41px 7px;
            margin-bottom: 30px;
            -webkit-transition: all .2s ease-in-out;
            -moz-transition: all .2s ease-in-out;
            -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;

            &:hover{
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -o-transform: scale(1.1);
                transform: scale(1.1);
                box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
                background-color: #171C31;

                .fund-type-span {
                    display: block;
                    text-align: left;
                    font: normal normal normal 13px/16px Arial;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    position: relative;
                    bottom: 23px;
                    padding-left: 6px;
                    padding-right: 5px;
                }
                .fund-type-p {
                    display: none;
                }

                .overview--funders_dashboard_home-items--input {
                    top: unset;
                    &-hover {
   
                       position: absolute !important;
                       left: 18% !important;
                       bottom: 28px !important;
                       
                        &:disabled {
                            background: rgba(227,227,227,0.20);
                            color: rgba(255,255,255,0.20);
                        }
                   }
   
               }


            }

            &--input {
                position: relative;
                top: 22px;
            }
            

            p {
                text-align: center;
                font: 500 24px/24px "bebas_neueregular";
                letter-spacing: 0px;
                color: #171C31;
                margin-bottom: 17px;
                white-space: pre-wrap;
                display: block;
            }
            span {
                display: none;
            }
            input {
                width: 139px;
                height: 35px;
                background: #36B5B0 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 0;
                border-radius: 20px;
                font: normal 18px/21px Arial;
                letter-spacing: 0px;
                color: #FFFFFF;

                &:disabled {
                    background: #E3E3E3 0% 0% no-repeat padding-box;
                    box-shadow: none;
                    pointer-events: none;
                }
                &:hover {
                    background-color: white;
                    color: #36B5B0;
                    border: 1px solid #36B5B0;

                    transition: background-color 0.75s;
                }
            }
        }
    }
    padding-left: 15px !important;

    &-profile {
        &-container {
            background: #2625419c;
        }
    }
    &-container {
        max-width: 1360px;
    }
    &-spacer {
        height: 100px;
        display: block;
    }

    &--main { 
        &-container {
            max-width: 1366px !important;
        }
    
    }
    &-button {
        font: normal 16px/18px Arial;
        background: white;
        color: #36B5B0;
        border: 1px solid #36b5b11c;
        border-radius: 12px;
        margin-right: 20px;
        margin-left: 20px;
        padding: 5px 14px;
        position: relative;
        left: -3px;

        &:hover {
            background-color: #36B5B0;
            color: white;

            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #3BB5B0;
        }
        &:active, &:focus {
            background: #309F9A 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #349F9A;
            outline: none;
            color: white;
                
        }
    }
    &-square {
        box-sizing: border-box;
        border: 1px solid #4A4A4A;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.09);

    }

    &-table {
        border-collapse: collapse;
        border-style: hidden;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 12px;
        overflow: hidden;
        background: white;
        margin: auto;
        width: 753px;
        margin-bottom: 50px;
        margin-top: 100px;
        table-layout: fixed;

        &-title {
            &-bold_name {
                max-width: 319px;
                min-width: 319px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                span {
                    font: bold 18px/39px Arial;
                    letter-spacing: 0px;
                    color: #171C31;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-left: 15px;
                    transition: color 3s;
                    
                }

            }
            &-normal_name {
                max-width: 153px;
                min-width: 153px;            
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                span {
                    font: normal 18px/39px Arial;
                    letter-spacing: 0px;
                    color: #171C31;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-left: 15px;
                }
            }
        }
        &-max_value {
            width: 700px;
        }

        thead {
            &.search_head {
                background-color: white;
                border-left: 1px solid #171C31;
                border-right: 1px solid #171C31;
                display: flex;
                padding: 15px;

                span {
                    flex: 1;
                    height: 35px;
                    border: 1px solid #171B31;
                    border-radius: 16px;
                    background: #35344B;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    font: bold 16px/18px Arial;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    padding-top: 8px;
                    padding-left: 15px;

                    &::before {
                        transition: 0.2s all ease-in-out;
                        content: "\f002";
                        display: block;
                        position: absolute;
                        font-family: "Font Awesome 5 Free";
                        font-size: 17px;
                        font-weight: 900;
                        color: #171C31;
                        right: 46px;
                    }
                }
   
                }
                input {
                    width: 80%;
                    border: 1px solid #171B31;
                    border-radius: 16px;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    padding: 0 12px;
                    text-align: left;
                    font: bold 14px/16px Arial;
                    letter-spacing: 0px;
                    color: #35344B;
                    &::placeholder {
                        text-align: left;
                        font: italic  14px/16px Arial;
                        letter-spacing: 0px;
                        color: #A2A1A1;
                    }

            }
            background-color: #171C31;

            tr {
                th {
                    table-layout: fixed;
                    &:nth-child(1){
                        width: 317px
                    
                    }
    
                    &:nth-child(2){
                        width:  157px;
                    }
                    
                    &:nth-child(3){
                        width:  262px;
                    }
                }
                p {
                    text-align: left;
                    font: bold 20px/23px Arial;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    padding: 15px;
                    padding-right: 0;
    
                }
            }

        }
        tbody {
            tr {
                border-bottom: 1px solid #F4F4F4;

                td {
                    table-layout: fixed;
                    &:nth-child(4){
                        width: 195px;
                    }
                }
            }

            /* width */
&::-webkit-scrollbar {
    width: 10px;
    margin: 0 10px;
    right:-100px;
       position: relative;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #E3E3E3 0% 0% no-repeat padding-box;
    border-radius: 6px;

  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #E3E3E3 0% 0% no-repeat padding-box;
  }
        }
        tbody:first-child {
            tr:first-child {
                td:first-child,
                th:first-child {
                    border-top-left-radius: 4px;
                }
    
                td:last-child,
                th:last-child {
                    border-top-right-radius: 4px;
                }
            }
        }
    
        tbody:last-child {
            tr:last-child {
                td:first-child,
                th:first-child {
                    border-bottom-left-radius: 4px;
                }
    
                td:last-child,
                th:last-child {
                    border-bottom-right-radius: 4px;
                }
            }
        }
    
        thead {
            tr {
                th {

                    &:nth-child(3),
                    &:last-child {
                        border-right: none;
                    }
                }
            }
            tr:last-child {
                td:first-child,
                th:first-child {
                    border-top-left-radius: 4px;
                }
    
                td:last-child,
                th:last-child {
                    border-top-right-radius: 4px;
                }
            }
        }
    
        tfoot {
            tr:last-child {
                td:first-child,
                th:first-child {
                    border-bottom-left-radius: 4px;
                }
    
                td:last-child,
                th:last-child {
                    border-bottom-right-radius: 4px;
                }
            }
        }
    
        // Reset tables inside table
        table tr th,
        table tr td {
            border-radius: 0;
        }
    }

    &-funder-profile {
        width: 524px;
        margin: 100px auto 40px auto;
  
        &-header {
            background: #171C31;
            border: 1px solid #171B31;
            border-radius: 12px 12px 0px 0px;

            h1 {
                font: normal 40px/48px "bebas_neueregular";
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                margin: 26px 31px;
            }

        }
        &-body {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #171B31;
            border-radius: 0px 0px 12px 12px;
            &--content {
                height: 588px;
                overflow-y: auto;
                            /* width */
&::-webkit-scrollbar {
    width: 10px;
    margin: 0 10px;
    right:-100px;
       position: relative;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #E3E3E3 0% 0% no-repeat padding-box;
    border-radius: 6px;

  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #E3E3E3 0% 0% no-repeat padding-box;
  }

            }
            &-image {
                -webkit-animation: fade 1s;
                animation: fade 1s;
                -moz-animation: fade 1s;
                -o-animation: fade 1s;
                width: 350px;
                height: 147px;
                margin: 28px 31px;
                text-align: center;
                &-no_logo {
                    background: #F4F4F4;
                    width: 100%;
                    height: 100%;
                    h1 {
                        text-align: center;
                        padding-top: 60px;
                        font: bold 20px/23px Arial;
                        letter-spacing: 0px;
                        color: #C1C1C1;
                    }
                    }
                

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            h3 {
                font: bold 24px/28px Arial;
                letter-spacing: 0px;
                color: #171C31;
                margin: 0 31px 28px 31px;
            }
            h4 {
                font: bold 22px/26px Arial;
                letter-spacing: 0px;
                color: #171C31;
                margin: 0 31px 7px 31px;
                text-transform: inherit;
            }
            p {
                font: normal normal normal 18px/21px Arial;
                letter-spacing: 0px;
                color: #171C31;
                margin: 0 31px 12px 31px;

            }
            button {
                background: #8E4091 0% 0% no-repeat padding-box;
                border: none;
                border-radius: 32px;
                color: white;
                font: bold 18px/21px Arial;
                letter-spacing: 0px;
                padding: 9px;
                margin: 30px 31px;
                width: 157px;

                &:hover {
                    box-shadow: 0px 3px 6px #00000029;
                }
                &:active, &:focus {
                    background: #752E77 0% 0% no-repeat padding-box;
                    box-shadow: 0px 6px 10px #00000029;
                    outline: none;
                
                }
                
            }
        }
    }

}



table.scroll tbody,
table.scroll thead { display: block; }

thead tr th { 
    height: 30px;
    line-height: 30px;
    /* text-align: left; */
}

table.scroll tbody {

    max-height: 673px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #171B31;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;


}

@media(max-width:1340px){
    table.scroll tbody {
        overflow-x: scroll;

    }
    .overview {
        
        &-table {
           width: 100%;
        }
        &-funder-profile {
            width: 100%;
        }
}
}
@media(max-width:1246px){
    table.scroll tbody {
        overflow-x: scroll;
    }
    
    .overview {
        
        &-button {
            left:  -41px;
        }

    }
}
@media (max-width:1169px) {
    .overview {
        &-table {
            thead {
                &.search_head {

                    input {
                        width:  75%;
                    }
                }
        }
    }
}
}
@media (max-width:900px) {
    .overview {
        &-table {
            thead {
                &.search_head {

                    input {
                        width: 67%;
                    }
                }
        }
    }
}
}
// Tablet specific styling
@media (max-width: 1024px) {

    .overview {
            &-max_value {
                max-width: 89px;
            }

            &-title {
               &-bold_name {
                max-width: 89px;
               }
               &-normal_name {
                max-width: 89px;
               }
            }
            
        }

        &-funder {
            &-profile {
                &-body {
                    img {
                        width: 93%;
                        margin: 31px 12px;
                    }
                }
            }
        }
    }
  // Mobile specific styling
  @media (max-width: 414px) {
    .overview {
        &-table {
                width: 100%;
                box-shadow: none;

                &-container {
                    overflow-x: auto;
                }
            }
    
            &-funder {
                &-profile {
                    width: 100%;
                    &-body {
                        img {
                            width: 93%;
                        }
                    }
                }
            }
        }
    }
  