.nav-fade {
// General styling
.header--navigation {
  background: transparent;
  box-shadow: none;
  &.fill {
      background: $brand-tertiary;
      box-shadow: $box-shadow;
    }
}
// Desktop specific styling
@media (min-width: $tablet) {
}

  // Mobile specific styling
  @media (max-width: $tablet) {
  }
}