.sidebar_left {

  // Local Variables
  $totalWidth: 461px;
  $panelWidth: 411px;
  $tabHeight: 50px; 
  $vertical: 50px;
  
  // General styling
  display: inline-block;
  position: fixed;
  top: $vertical;
  height: calc(100vh - #{$vertical}); // minus top height
  overflow: hidden;
  left: -$panelWidth;
  width: $totalWidth;
  transition: .2s all ease-in-out;
  z-index: 1001; // 1 higher than leaflet's map's zoom component
  &-link {
    font: normal normal bold 18px/26px Arial;
    letter-spacing: 0px;
    
  }
  &--deactive {
    opacity : 20%;
    pointer-events: none;
    cursor: not-allowed;
  }
  &--desktop {
    display: block;
  }
  &--mobile{
    display: none;
    h1 {
      margin: auto;
      text-align: center;
      font-size: 50px;
      font-family: Arial, Helvetica, sans-serif;
      color: white;
      position: relative;
      top: 25vh;
      padding: 15px;
    }
  }
  &--topav {
    width: 100%;
    height: 50px;
    display: block;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 10000;
    position: fixed;
    top: 0;

    &-white {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px 22px 22px 0px;
      width: 61px;
      height: 50px;
      position: absolute;
      left: 0;
      z-index: 41;
      cursor: pointer;


      i {
        font-size: 30px;
        margin-top: 11px;
        margin-left: 13px;
        color: #171C31;
      }
    }

    &-orange {
      background: #EE7E18 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px 22px 22px 0px;
      font: 400 36px/43px "bebas_neueregular";
      letter-spacing: 0px;
      color: #FFFFFF;
      text-transform: uppercase;
      position: relative;
      top: 3px;
      padding: 4.5px 32px 4.5px 79px;
      bottom: 1px;
      cursor: pointer;

      i {
        font-size: 22px;
        bottom: 2px;
        left: 7px;
        position: relative;
        transition: transform 0.65s ease 0s;
      }
    }
    &-header {
        font: bold 20px/23px Arial;
        letter-spacing: 0px;
        color: #171C31;
        margin-left: 19px;
        position: relative;
        top: -1px;
    }
  }
  &--tab {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    background: $brand-primary;
    border-radius: 0 5px 5px 0;
    height: $tabHeight;
    &--trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;  
    }
    span {
      z-index: 1;
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;
      line-height: 24px;
      font-size: 24px;
      color: #B2BEC3;
      font-weight: 600;
      top: 15px;
      left: 50px;
    }
    i {
      z-index: 1;
    }

    .icon {
      &-left, &-right {
        height: 100%;
        width: 24px;
        position: relative;
      }
      &-left {
        left: 10px;
      }
      &-right {
        right: 14px;
      }
    }

    .circle {
      position: relative;
      right: 10px;
      display: inline-block;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-color: #B2BEC3;
      .fas {
        margin: 0;
        font-size: 18px;
        top: 1px;
        left: 9px;
        position: absolute;
        color: #B2BEC3;
      }
    }
  }
  &--panel {
    margin: 0;
    width: $panelWidth;
    position: relative;
    left: 0;
    padding: $s-s;
    border-radius: 0  0 $border-radius 0;
    height: 100%;
  background: #35344B 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px #00000029;
    overflow-x: hidden;

    a {
      span {
        color: #FFFFFF !important;
      }
    }
    &::-webkit-scrollbar {
      width: 0;
      margin: 0;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      display: none;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    

    // Custom Sidebar Content
    a {
      font-size: 18px;
      line-height: 50px;
      margin-left: 12px;
      border-bottom: 4px solid transparent;
      display: inline-block;
      color: white;
      &:hover {
        text-decoration: underline;
      }
      &.active {
        font-weight: 600;
        border-bottom: 4px solid $font-general;
      }
    }
  }
  &.open {
    left: 0;
    z-index: 1003;
    i.circle {
      transform: rotate(180deg);
    }
  }
  // Desktop specific styling
  @media (min-width: $tablet) {
  }
  
    // Mobile specific styling
    @media (max-width: $tablet) {
      &--desktop {
        display: none;
      }
      &--mobile{
        display: block;
      }
    }
  }
  
  