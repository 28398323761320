
.switch {
    position: relative;
    display: inline-block;
    width: 190px;
    height: 36px;
    opacity: 1;
    top: 5px;
    &-resources {
      display: block;
      margin: auto;
      width: 65.5%;
    }
  }
  .position-relative{
    position: relative;
  }
  .disable{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    cursor: not-allowed;
  }
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF;
    -webkit-transition: .4s;
    transition: .4s;
    align-items: center;
    display: flex;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 38px;
    width: 51%;
    top: -1px;
    left: 0;
    bottom: 4px;
    background-color: #EE7E18;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 16px !important;
    box-shadow: 0px 3px 20px #00000029;
  }

  
  
  input:checked + .slider {
      //green
    background-color: #FFFFFF;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }
  
  /*------ ADDED CSS ---------*/

  
  .map
  {
    text-align: center;
    font: normal 15px/17px Arial;
    letter-spacing: 0px;
    color: #4C4D5D;
    position: absolute;
    left: 33px;
    top: 10px;
    text-transform: initial;
    &-center {
      left: 25% !important;
      width: 50%;
      text-align: center;
    }
    &.true{
      font-weight: bolder;
    }
  }
  .listing {
    text-align: center;
    font: normal 15px/17px Arial;
    letter-spacing: 0px;
    color: #4C4D5D;
    position: absolute;
    right: 22px;
    top: 10px;
    text-transform: initial;
    &-center {
      width: 50%;
      left: 75%;
      text-align: center;
    }
    &.true{
      font-weight: bolder;
    }
  }




  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 16px;
  }
  
  .slider.round:before {
    border-radius: 50%;}

@media (max-width: $tablet) {
    .slider{
        left: -6px;
    }
    .switch {
        height: 105px;
        width: 100%;
        display: block;
        margin: auto;
      &-resources {
        height: 80px;
        width: 98%;
        left: 3px !important;

      }
      
      }
    .slider:before {
        height: 105px;
    }

    .map
    {
      font: 500 16px/33px;
      left: 26% !important;
      width: 51%;
      text-align: center;
      &.true{
        font-weight: 800;
      }

    }
    .listing {
        font: 500 16px/33px;
        right: 29px !important;
        text-align: center;
        width: 51%;
        top: 52px;
        left: 77%;
        &.true{
          font-weight: 800;
        }
    }
    

}