.background-filler {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .8;
}
.btn{
    border: 1px solid #131421;
    border-radius: 12.5px;
    background-color: #FFFFFF;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    line-height: 19px;
    padding: 5px 20px;
    transition: all 0.2s ease-in-out;
    &:hover{
        box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12);
    }
}
hr.dotted{
    border-top: none;
    height: 4px;
    overflow: hidden;
    position: relative;
    &:after{
        color: black;
        content: ".......................................";
        letter-spacing: 20px;
        font-size: 32px;
        display: block;
        line-height: 0px;
        position: relative;
        top: -6px;
    }
}
.line{
    border: 1px solid #000000;
    width: 100%;
}
.no-pointer-events {
    pointer-events: none;
}

.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.no-margin {
    margin: 0 !important;
}
.no-padding {
    margin: 0 !important;
}
.click-layer {
    width: 100% !important;
    height: 100% !important;
    display: block;
    position: absolute !important;
    z-index: 3 !important;
  }
.trigger{
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
}
// Desktop specific styling
@media (min-width: $tablet) {
    .mobile{
        display: none;
    }
}

  // Mobile specific styling
  @media (max-width: $tablet) {
    .desktop{
        display: none;
    }
  }