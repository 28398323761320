.landing-page {
    // background-color: #35334B;
    // height: 100vh;
    margin-top: 110px;
    .container {
        max-width: 940px !important;
        .top-section {
            h1 {
                text-align: left;
                font: normal 45px/26px Arial;
                letter-spacing: 0px;
                color: #FFFFFF;
                margin-bottom: 25px;
            }
            a {
                background: #EE7E18 0% 0% no-repeat padding-box;
                border-radius: 16px;
                text-align: center;
                font: bold 18px/26px Arial;
                letter-spacing: 0px;
                color: #FFFFFF;
                width: 140px;
                text-decoration: none;
                height: 38px;
                padding: 7px;
                z-index: 99;

            }
        }
        .table-section {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }
}