.innovator_profile{
    &-back {
        height: 25px;
    }
    &-An_interview_with_the_Innovator {
        margin-bottom: 60px !important;
    }
    &-advice_from_the_Innovator {
        padding-bottom: 130px;
        width: 84%;
        font-size: 18px;
    }
    &-back_btn {
        margin-bottom: 80px !important;
        &-text {
            padding-left: 10px !important;
            position: relative;
            bottom: 4px;
            margin-right: 5px;
        }
        &-icon {
            position: relative;
            bottom: 4px;
        }
    }
    background-color: #FFFFFF;
    padding: 60px 0 80px 0px;
    font-family: Catamaran;
    &--header{
        font-family: Catamaran;
        .image{
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 256px;
            width: 255px;
        }
        .header-content{
            color: #2D3036;
            h4{
                font-size: 40px;
                font-weight: bold;
                text-transform: uppercase;
                line-height: 30px;
            }
            h6{
                font-size: 26px;
                font-weight: 500;
                text-transform: capitalize;
            }
            p{
                font-size: 18px;
                font-weight: 500;
                text-transform: capitalize;
            }
            a {
                font-size: 18px;
                font-weight: 500;
                text-transform: capitalize;
                color: #2D3036 !important;
            }
        }
    }
    &--interview{
        background-color: #6EB7CB;
        font-family: Catamaran;
        color: #FFFFFF;
        h4{
            font: normal 500 40px/1.5 "Catamaran";
            text-transform: none;
            margin-top: 80px;
        }

        .interview-content{

            h1{
                opacity: 0.5;
                color: #FFFFFF;
                font-size: 50px;
                font-weight: 500;
                position: relative;
                bottom: 8px;
            }

            p.question{
                font-size: 20px;
                font-weight: bold;
                //font-family: "Helvetica Neue";
            }
            p.answer{
                font-size: 18px;
                
            }

        }
    }
    &--video{
        background-color: #272730;
        .video-container{
            .video--cover{
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                height: 516px;
                margin: 80px;
                margin-left: 92px;
                margin-right: 92px;
                cursor: pointer;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &--sponsor_logos{
        &-space-bottom {
            margin-bottom: 80px !important;
            margin-top: 80px !important;
        }
        label{
            margin-top: 0;
        }
        p{
            font-size: 16px;
            color: #131421;
            text-align: center;
        }
        .logo{
            max-height: 266px;
            max-width: 255px;
            margin: 15px;
            margin-bottom: 24px;
            img{
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 255px;
            
            }
            .SolaPac1 {
                width: 125px !important;
            }

        }
        .flex-direction{
            flex-direction: column;
        }
    }
    &--footer{
        border-top: 1px solid #131421;
        padding-top: 20px;
        margin-left: 92px;
        margin-right: 92px;
        p{
            opacity: 0.8;
            color: #2D3036;
            font-family: Catamaran;
            font-size: 12px;
            letter-spacing: -0.24px;
            
        }
    }
    &-container {
        padding-top: 80px !important;
    }
    &-supporting {
        padding-top: 0 !important;
    }
    .innovation--picture{
        img{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 60%;
            position: relative;
        }
        .col {
            text-align: center;
        }
    }
    .how-innovation-works {
        margin-top: 0 !important;
    }
    .supporting {
        margin-top: 130px !important;
    }
    .question{
        border-left: 2px solid #000000;
        margin: 80px 60px;
        .question-content{
            padding-left: 30px;
            h6{
                color: #000000;
                font-size: 30px;
                padding-bottom: 20px;
                position: relative;
                bottom: 5px;
            }
            p{
                font-size: 18px;
                font-weight: 200;
                color: #131421;
                position: relative;
                top: 8px;
            }
        }
    }

    // Mobile specific styling
  @media (max-width: $tablet) {
    &--interview{
        h4{
            font-size: 29px;
        }
    }
    &--header{
        .image{
            width: 100%;
        }
        .header-content{
            padding: 25px;
            p{
                font-size: 12px;
            }
        }
    }
    &--video{
        .video-container{
            .video--cover{
                height: 210px;
                margin: 28px 5px;
            }
        }
    }
    .question{
        margin-left: 30px;
    }
      .btn_primary{
        margin: auto;
        display: block;
        width: 50px;
        height: 50px;
        top: 20px;
        left: 20px;
        position: fixed;
        z-index: 10000;
        background-color: #FFFFFF;
      }
  }

}
.questions-body {
    margin-left: 77px;
}

  // Mobile specific styling
  @media (max-width: $tablet) {
   .innovator_profile {
       padding: 0;
       &-back_btn{
           &-icon {
           font-size: 30px;
           position: relative;
           top: 10px;
           right: 14px;
       }
       &-text {
           display: none;
       }
    }
       &--video {
           margin-top: 80px;
           padding: 30px;
       }
       .innovation--picture{
        img{
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            position: relative;
            left: 0;
            margin-bottom: 50px;
            padding: 30px;
        }
    }
    &-advice_from_the_Innovator {
        width: 100%;
    }
   }

   .questions-body {
    margin-left: 0;
}
  }