.accordion {
    width: 447px;
    margin-bottom: 27px;
    z-index: 1;
    &:nth-child(even) {    
        position: relative;
        left: 17px;}
    &-button {
        background-color: #35334B !important;
        border-radius: 12px !important;
        padding: 19px 23px !important;
        height: 90px;
        div {
            align-items: center;
            span {
                font: bold 20px/26px Arial;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
                vertical-align: middle;
                width: 240px;
            }
            border-right: 1px solid #FFFFFF33;
            margin-right: 19px;
        }

        a {
            background: #36B5B0 0% 0% no-repeat padding-box;
            border: 1px solid #3BB5B0;
            width: 81px;
            opacity: 1;
            padding: 9px;
            font: normal normal normal 18px/20px Arial;
            letter-spacing: 0px;
            color: #FFFFFF;
            border-radius: 16px;
            text-decoration: none;
            text-align: center;
            margin-right: 20px;
            height: 38px;
            &:hover {
                color: rgba(255, 255, 255, 0.5);

            }
        }

        &::after {
            background-image: url("/static/dist/img/angle-left.svg") !important;
            height: 14px !important;
            position: relative;
            top: 1px;

        }

        &:focus {
            border: none !important;
            box-shadow: none !important;
        }
    }

    &-item {
        background-color: transparent !important;
        border: 1px solid #FFFFFF33 !important;
        border-radius: 12px !important;
        opacity: 1;

        &:not(.show) {
            
        }
    }

    &-body {
        background-color: white !important;
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        font: normal 16px/24px Arial;
        letter-spacing: 0px;
        color: #35344B;
        padding-top: 1px !important;
        padding-left: 23px !important;
    }
}

.active {

    .accordion-button {

        &:not(.collapsed) {
            background-color: white !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            box-shadow: none !important;
            div {
                span {color: #35354B !important}
                border-right: 1px solid #BEBFC7;
            }

            a {
                background: white 0% 0% no-repeat padding-box;
                color: #36B5B0;
            }

            &::after {
                background-image: url("/static/dist/img/angle-left-dark.svg") !important;
                transform: rotate(360deg) !important;
                transition: transform .1s ease-in-out !important;
            }

        }

    }
}