.indicator{
    &-chart_number_innovation_funds {
        width: 77.33333% !important;
        &-col {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }
    &-vertical_center {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
        font-family: Montserrat;
        position: relative;
        bottom: 10px;
    }
    &-graph-img {
        width: 100%;
        margin: auto;
        display: block;
    }
    font-family: Catamaran;
    color: #4A4A4A;
    &-short_text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    &-innovation_events{
        color: #545454;
        font-family: Montserrat;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 800;
        position: relative;
        top: 22px;
        &-year {
            color: #545454;
            font-family: Montserrat;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
        }
    }
    &-big_number {
         font-family: Catamaran;
         font-size: 100px;
         font-weight: bold;
         letter-spacing: 0;
         line-height: 100px;
         text-align: center;

         &-53 {
             color: #E17055;
         }
         &-40 {
             color: #F73E55;
         }
         &-136 {
             color:#3C978F;
         }
         &-269 {
            color:#3C978F;
            position: relative;
            right: 10px;
        }
    }
    svg {
        width: 100%;
        height: 100%;
    }
    p{
        font-size: 18px;
    }
    .source-info--link {
        color: #757575;
        font-family: Montserrat;
        font-size: 18px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 30px;
    }
    hr{
        border: 1px solid #000000 !important;
        margin-top: 40px;
    }
    
    .btn-download{
        font-size: 16px;
        font-weight: 600;
        border: 1px solid #485460;
        color: #2D3036;
        border-radius: 5px;
        background-color: #FFFFFF;
    }
    .heading{
        color: #000000;
        font-family: Catamaran;
        .btn{
            padding: 5px 30px;
            font-weight: bold;
            color: #FFFFFF !important;
            font-size: 16px;
            min-width: 160px;
        }
        .btn-investment{
            background-color: #FBB900;
            border: 1px solid #FBB900;
            height: 34px;
            padding: 6px 10px;
        }
        .btn-infrastructure{
            background-color: #2E70AC;
            border: 1px solid #2E70AC;
            height: 34px;
            padding: 6px 10px;
        }
        .btn-ecosystem {
            background-color: #38B99F;
            border: 1px solid #38B99F;
            height: 34px;
            padding: 6px 10px;
        }
        .btn-impact {
            background-color: #BF77FF;
            border: 1px solid #BF77FF;
            height: 34px;
            padding: 6px 10px;
        }
        .btn-pink{
            background-color: #F73E55;
            border: 1px solid #F73E55;
            height: 34px;
            padding: 6px 10px;
        }
        span{
            font-size: 18px;
            font-weight: 400;
        }
        h5{
            font-size: 30px;
            font-family: Catamaran;
            text-transform: unset;
        }
    }
    .source{
        &-name_copy {
            color: #5E5E5E;
            font-family: Montserrat;
            font-size: 16px;
            font-style: italic;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 27px;
        }
        margin-top: 40px;
        &-info{
            font: normal 400 16px/1.5 "Montserrat";
            font-style: italic;

            &-2 {
                margin-left: 78px;
            }
        }
        &-label{
            font: italic 600 16px/1.5 "Montserrat";
            text-transform: uppercase;
            font-style: italic;
        }
    }
    .value_of_innovation_funding{
        tbody{
            td{
                &:nth-of-type(2){
                    color: #FFFFFF;
                    background-color: #647586;
                    width: 60%;
                }
            }

        }
    }
    &-table{
        font-family: Montserrat;
        thead{
          th{
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            color: #FFFFFF;
            background-color: #647586;
            border: 2px solid #FFFFFF;
            border-left: 3px solid #FFFFFF;
            border-bottom: 3px solid #FFFFFF;
            text-transform: capitalize;
            font-family: Montserrat;
            line-height: 23px;
            &:first-of-type{
                width: 25%;
            }
          }
        }
        tbody{
        .blue {
            background-color: #4A91CE;
        }
          .orange {
              background-color: #F5A623;
          }
          .green {
              background-color: #8DB566;
          }
          .light-green{
            background-color: #8BDE9E;
          }
          .light-red{
            background-color: #FF698A;
          }
          td{
            font-weight: 500;
            font-size: 16px;
            border: 2px solid #FFFFFF;
            border-left: 3px solid #FFFFFF;
            color: #4A4A4A;
            background-color: #D0E0FF;
            &:nth-of-type(1){
              color: #FFFFFF;
              background-color: #647586;
            }
          }
        }
      }
}