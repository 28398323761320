textarea {
  // General styling
  font: normal 14px/16px Arial;
  letter-spacing: 0px;
  color: #35344B;
  padding: 11px;
  border: 1px solid #F5F5F7;
  border-radius: 7px;
  transition: .2s all ease-in-out;
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  resize: none;


  // Placeholder
  &::placeholder {
    font: normal 14px/16px Arial;
    letter-spacing: 0px;
    color: #35344B;
    opacity: 0.7;
    
  }


  // Desktop specific styling
  @media (min-width: $tablet) {}

  // Mobile specific styling
  @media (max-width: $tablet) {}
}
.text-area.dynamic {
    margin-bottom: 10px;
  textarea {
    // General styling
    display: block;
    padding-top: 26px !important;

  }
  .label {
    position: relative;
      top: 22px;
      left: 14px;
      margin: 0;
  }
}