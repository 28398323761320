.funder-directory {
    margin-top: 14px;
    h1 {
        font: bold 18px/21px Arial;
        letter-spacing: 0px;
        color: #35344B;
        margin: auto 0;
        position: relative;
        top: 2px;
    }
    .add-new-funder {
        border: 1px solid #333348;
        border-radius: 16px;
        font: normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #333448;
        text-decoration: none;
        width: 141px;
        text-align: center;
        padding: 9px;
        height: 36px;
    
    }
    .read-popup {
        width: 339px;
        /* UI Properties */
        background: #FCFCFC 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 6px #00000014;
        border: 0.5px solid #E3E3E3;
        padding: 16px 40px 16px 16px;
        position: absolute;
        z-index: 1;
        top: 70px;
        border-radius: 7px;
        a {
            position: absolute;
            right: 16px;
            top: 16px;
        }
        span {
            font: normal 14px/24px Arial;
            letter-spacing: 0px;
            color: #35344B;
            margin: 0;
            max-width: unset;
            mark {
                padding: 0 !important;
            }
        }
        &.info-box {
            top: 212px;
            p {
                font:  normal 14px/24px Arial;
                    letter-spacing: 0px;
                    color: #35344B;

                    b {
                        font:  bold 14px/24px Arial;
                        letter-spacing: 0px;
                        color: #35344B;
                    }
            }
        }
    }
}