.explainer {
    margin-bottom: 30px;
    position: relative;
    a {
        position: relative;
        bottom: 1px;
    }
    h2 {
        font: normal 20px/26px Arial;
        letter-spacing: 0px;
        color: #3BB5B0;
        margin-left: 12px;
    }
    .d-flex {
        cursor: pointer;
    }
    &--container {
        width: 339px;
        /* UI Properties */
        background: #FCFCFC 0% 0% no-repeat padding-box;
        box-shadow: 1px 1px 6px #00000014;
        border: 0.5px solid #E3E3E3;
        padding: 16px 40px 16px 16px;
        position: absolute;
        border-radius: 7px;
        top: 39px;
        z-index: 1000;
        p {
            font: normal 14px/24px Arial;
            letter-spacing: 0px;
            color: #35344B;
        }
        a {
            position: absolute;
            right: 16px;
            top: 16px;
        }
    
    }
    
}