.sub-navbar{
    background: #ffffff;
    border: 1px solid #DDDDDD;
    padding: 30px;
    position: absolute;
    left: 0;
    top: 39px;
    z-index: 1;
    .dropdown-item{
        &:hover{
            &::before{
                font-size: 16px;
                font-family: "Font Awesome 5 Free", "FontAwesome";
                margin-right: 5px;
                content: "\f061";
            }
        }
    }
    p,a{
        color: #777777;
        font-size: 14px;
        line-height: 42px;
        &:hover{
            color: #EC9744;
            font-weight: bold;
            
        }
    }
    p{
        text-transform: uppercase;
    }
    a{
        padding: 0;
        letter-spacing: -1.14px;
    }
}