.search {
    position: relative;
    &::after {
        content: url('/static/dist/img/search.svg');
        position: absolute;
        right: 0px;
        top: 4px;
        background: white;
        /* height: 30px; */
        padding: 9px 10px 5px 10px;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }
    input {
        width: 183px;
        height: 38px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 1px #00000014;
        border: 0.5px solid #DFE0E5;
        border-radius: 7px;
        font: normal normal normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #35344B;
        padding: 0 13px;
        margin-top: 4px !important;
        &::placeholder {
            font: normal normal normal 14px/16px Arial;
            letter-spacing: 0px;
            color: #35344B;
        }

    }
}