.add-new-funder {
    .header-section {
        padding: 28px 28px;
        .new-funder {
            font: bold 18px/21px Arial;
            letter-spacing: 0px;
            color: #35344B; 
            text-decoration: none !important;
            align-items: center;
            display: flex;

            img {
                margin-right: 38px;
            }
        }
        .delete-funder {
            width: 127px;
            height: 36px;
            /* UI Properties */
            border: 1px solid #333348;
            border-radius: 16px;
            text-align: center;
            font: normal 14px/16px Arial;
            letter-spacing: 0px;
            color: #333448;
            padding: 11px;
        }
    }
    .body-section {
        background: #fafafb;
        height: 47px;
        align-items: center;
        display: flex;
        padding-left: 30px;
        margin: 12px 0 22px 0;
        &:first-child {margin-top: 0 !important;}

            h1 {
                text-align: left;
                font: normal normal bold 14px/16px Arial;
                letter-spacing: 0px;
                color: #4C4D5D;
                margin: 0;
            }
        }
    .footer-section {
        margin-top: 62px;
        margin-bottom: 62px;
        text-align: center;
        .cancel {
            padding: 8px 32px;
            /* UI Properties */
            height: 36px;
            border: 1px solid #35334B;
            border-radius: 16px;
            text-align: center;
            font: normal 16px/26px Arial;
            letter-spacing: 0px;
            color: #35344B;
            position: relative;
            width: 116px !important;
            text-decoration: none;

        }
        .add {
     
            height: 36px;
            width: 161px !important;
            text-align: center;
            font: normal 16px/26px Arial;
            letter-spacing: 0px;
            color: #FFFFFF;
            background: #EE7E18 0% 0% no-repeat padding-box; 
            border: 1px solid #EE7E18;
            border-radius: 16px;
            position: relative;
            margin-left: 19px;
            &:hover,&:focus,&:active {
                background: #f57600  0% 0% no-repeat padding-box; 
                border: 1px solid #f57600 ;
            }
            &:disabled {
                opacity: 0.5 !important;
            }
        }
    }
}