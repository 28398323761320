select {
    width: 265px;
    height: 38px;
    // background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 1px #00000014;
    border: 0.5px solid #DFE0E5;
    border-radius: 7px;
    font: bold 16px/18px Arial;
    letter-spacing: 0px;
    color: #35344B;
    padding: 0 13px;
    margin-right: 15px !important;
    margin-top: 4px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background:#FFFFFF url('/static/dist/img/selector chevron down.svg') no-repeat 240px center;
    &:active,&:focus,&:focus-visible {
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
    }
}