@keyframes dot-keyframes {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
  
    50% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }
  
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
  }
  
  .loading-dots {
    text-align: center;
    width: 100%;
    position: relative;
    top: 66px;
    left: 0px;
    &--background {
        background-color: #F4F4F4;
    }
    
    &--dot {
        animation: dot-keyframes 1.5s infinite ease-in-out;
        background-color: #000;
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-right: 10px;
      
      &:nth-child(2) {
        animation-delay: .5s;
      }
      
      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
  }

  .my-loader {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;

    &--background {
      background-color: #F4F4F4;
  }
    &--dot {
      animation: dot-keyframes 1.5s infinite ease-in-out;
      background-color: #FFFFFF;
      border-radius: 50%;
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 10px;
    
    &:nth-child(2) {
      animation-delay: .5s;
    }
    
    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
  }