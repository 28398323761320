.dynamic-select {
    height: 56px;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F5F5F7;
    border-radius: 7px;
    padding: 11px;
    position: relative;
    ::after {
        content: url("/static/dist/img/selector chevron down.svg");
        position: absolute;
        right: 16px;
        top: 31px;
    }

    &.right {
      margin-right: 5px;
    }
    &.left {
        margin-left: 5px;
    }
    label {
        margin: 0;
    }
    select {
        border: none;
        box-shadow: none;
        height: 20px;
        padding: 0;
        text-align: left;
        font: normal 14px/16px Arial;
        letter-spacing: 0px;
        color: #35344B;
        width: 100%;
        background: #fff;
        &::-ms-expand {
            display: none;
        }
    }

}