.responsive-table {
    table {
        margin: 14px auto;
        width: 100%;
        overflow-y:scroll;
        // height:calc(100vh - 162px);
        display:table;
        table-layout: fixed;
        thead{
            display: table-header-group;
        tr {
            th {
                background: #ceced6 0% 0% no-repeat padding-box;
                text-align: left;
                font: bold 14px/16px Arial;
                letter-spacing: 0px;
                color: #4C4D5D;
                padding: 16px 15px;
                border-bottom: 1px solid #F5F5F7;
                overflow: hidden;
                
                .fund-scale {
                    width: 18px;
                    height: 18px;
                    /* UI Properties */
                    background: #5C5B77 0% 0% no-repeat padding-box;
                    border-radius: 50%;
                    font: normal 13px/15px Arial;
                    letter-spacing: 0px;
                    color: #FFFFFF !important;
                    padding: 3px 7px;
                    margin-left: 10px;
                }
            }
        }
        }
        tbody {
                display: table-header-group;
            tr {
                
                td {
                    padding: 16px 15px;
                    text-align: left;
                    font: bold 14px/15px Arial;
                    color: #4C4D5D;
                    border-bottom: 1px solid #F5F5F7;
                    position: relative;
                    height: 69px;
                    nobr {
                        span {
                            span,mark {
                                padding: 0;
                                text-align: left;
                                font: bold 14px/15px Arial;
                                color: #4C4D5D;
                                border-bottom: none;
                                position: relative;
                                height: 69px;
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
    
                    // overflow: hidden;
                    p {
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    &.name {
                        max-width: 268px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .funder-profile {
                        text-align: left;
                        text-decoration: underline !important;
                        font: Normal 14px/16px Arial;
                        letter-spacing: 0px;
                        color: #EE7E18 !important;
                        mark {
                            text-align: left;
                            text-decoration: underline !important;
                            font: Normal 14px/16px Arial;
                            letter-spacing: 0px;
                            color: #EE7E18 !important;
                        }
                    }
                    .explore{
                        border: 1px solid #DFE0E5;
                        border-radius: 7px;
                        padding: 7px;
                        &:first-child {
                            margin-right: 10px;
                        }
                        &:last-child {
                            padding: 7px 8.5px;
                        }
                    }
                    .phone {
                        background: #FAFAFA 0% 0% no-repeat padding-box;
                        border-radius: 7px;
                        padding: 6px 12px 6px 35px;
                        font: normal 14px/16px Arial;
                        letter-spacing: 0px;
                        color: #707184;
                        margin-right: 10px;
                        position: relative;
                        padding-top: 10px;
                        max-width: 134px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &::before {
                            content: url('/static/dist/img/mobile-android.svg');
                            position: absolute;
                            left: 14px;
                        }
                    }
                    .email {
                        background: #FAFAFA 0% 0% no-repeat padding-box;
                        border-radius: 7px;
                        padding: 6px 12px 6px 35px;
                        position: relative;
                        max-width: 268px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        a {
                            font: normal 14px/16px Arial;
                            letter-spacing: 0px;
                            color: #707184;
                            text-decoration: none;
                            position: relative;
                            top: 2px;
                        }
                        
                        &::before {
                            content: url('/static/dist/img/envelope-alt.svg');
                            position: absolute;
                            left: 10px;
                            top: 10px;
                        }
                    }
                    .grid {
                        margin-right: 21px;
                    }
                }
            }
        }

        }
}
