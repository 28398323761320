input[name="dates"] {

    // General styling
  width: 100px;
  // Desktop specific styling
  @media (min-width: $tablet) {
  }
  
    // Mobile specific styling
    @media (max-width: $tablet) {
    }
  }